module.exports = new Promise((resolve,reject) => {    
        fetch('./assets/apps.config.json').then(response => response.json()).then(res => {
      const remoteUrl =  res.axpap.baseUrl + '/remoteEntry.js?' + new Date().getTime()
      const script = document.createElement('script')
      script.src = remoteUrl
      script.onerror = reject
      script.onload = () => {
        // the injected script has loaded and is available on window
        // we can now resolve this Promise
        const proxy = {
          get: (request) => window.axpap.get(request),
          init: (arg) => {
            try {
              return window.axpap.init(arg)
            } catch(e) {
              console.log('remote container already initialized')
            }
          }
        }
        resolve(proxy)
      }
      // inject this script with the src set to the versioned remoteEntry.js

        document.head.appendChild(script);

    });
    })
    ;